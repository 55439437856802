<template>
  <div class="column pt-0">
    <CatalogDataModal
      :active="isModalActive"
      :selected-item="selectedCatalogData"
      :catalog_id="catalog_id"
      :catalog-fields="fields"
      @close="closeDialog"
      @saved="fetchCatalogData"
    />
    <div class="is-flex is-justify-content-space-between is-align-items-center mb-4">
      <h2 class="is-size-5 has-text-weight-semibold mb-4">{{ name || 'Каталог' }}</h2>
      <b-button type="is-info" @click="openDialog">Добавить</b-button>
    </div>
    <b-table
      :data="data"
      :loading="isLoading"
      hoverable
      striped
      class="base-table"
      @cellclick="openDialog"
    >
      <b-table-column field="id" label="ID" v-slot="props">
        {{ props.row.id }}
      </b-table-column>
      <b-table-column field="agent.device_name" label="Агент" v-slot="props">
        {{ props.row.agent ? props.row.agent.device_name : '-' }}
      </b-table-column>
      <b-table-column
        v-for="field in fields"
        :key="field"
        :field="field"
        :label="field"
        v-slot="props"
      >
        {{ props.row.fields_data[field] }}
      </b-table-column>
      <template #empty>
        <div class="has-text-centered">Нету данных</div>
      </template>
    </b-table>
  </div>
</template>

<script>
import CatalogDataModal from "@/components/Catalog/CatalogDataModal.vue"

export default {
  name: "CatalogShow",
  components: { CatalogDataModal },
  props: ['catalog_id'],
  data() {
    return {
      isLoading: true,
      name: '',
      data: [],
      fields: [],
      fieldsData: [],
      isModalActive: false,
      selectedCatalogData: undefined
    }
  },
  mounted() {
    this.fetchCatalogData()
  },
  methods: {
    async fetchCatalogData() {
      this.isLoading = true
      try {
        const { data } = await this.$axios.get(`admin/catalogs/${this.catalog_id}/catalogs-data`)
        this.data = data?.data || []
        this.fieldsData = data?.data?.['fields_data'] || []
        this.fields = data?.catalog?.['fields'] || []
        // this.fields = (data?.data?.[0]?.['fields_data'] && Object.keys(data?.data?.[0]?.['fields_data'])) || []
      } catch (e) {
        throw new Error(e)
      }
      this.isLoading = false
    },
    openDialog(item) {
      this.selectedCatalogData = item
      this.isModalActive = true
    },
    closeDialog() {
      this.isModalActive = false
      this.selectedCatalogData = undefined
    },
  }
}
</script>

<style scoped>

</style>
