<template>
  <div>
    <b-modal
      :active="active"
      width="50vw"
      scroll="keep"
      @close="$emit('close')"
    >
      <b-loading v-model="isLoading" :can-cancel="true"></b-loading>
      <div v-if="selectedItem" class="card px-5 pt-6 pb-4">
        <b-field
          label="Агент"
          :message="errors && errors['agent_id']"
          :type="errors && errors['agent_id'] && 'is-danger'"
        >
          <b-select v-model="selectedAgent" placeholder="Выберите агента">
            <option
              v-for="agent in agents"
              :value="agent.id"
              :key="agent.id">
              {{ agent.device_name }}
            </option>
          </b-select>
        </b-field>
        <b-field
          v-for="(value, key) in fieldsData"
          :key="key"
          :label="key.toString()"
          :message="errors && errors[`fields_data.${key}`] "
          :type="errors && errors[`fields_data.${key}`] && 'is-danger'"
        >
          <b-input v-model="fieldsData[key]" />
        </b-field>
        <div class="has-text-right pt-2">
          <ConfirmAction v-if="selectedItem.id" class="mr-2" @confirm="deleteCatalogData"/>
          <b-button type="is-info" @click="saveCatalogData">Сохранить</b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import ConfirmAction from "@/components/Base/ConfirmAction.vue"
export default {
  name: "CatalogDataModal",
  components: { ConfirmAction },
  props: ["active", "selectedItem", "catalog_id", "catalogFields"],
  data() {
    return {
      isLoading: false,
      createLoading: false,
      fields: [],
      fieldsData: [],
      agents: [],
      selectedAgent: undefined,
      errors: []
    }
  },
  watch: {
    active(newValue) {
      if(newValue && this.selectedItem['id']) {
        this.fetchAgents()
        this.fetchCatalogData()
      } else if(newValue) {
        this.fetchAgents()
        this.arrayToObject()
      } else if(!newValue) {
        this.selectedAgent = undefined
        this.fieldsData = []
        this.fields = []
        this.errors = []
      }
    }
  },
  methods: {
    async fetchAgents() {
      try {
        const { data } = await this.$axios.get('admin/agents')
        this.agents = data?.data || []
      } catch (e) {
        throw new Error(e)
      }
    },
    async fetchCatalogData() {
      this.isLoading = true
      try {
        const { data } = await this.$axios.get(`admin/catalogs-data/${this.selectedItem['id']}`)
        this.fields = data?.data?.catalog?.['fields'] || []
        this.fieldsData = data?.data?.['fields_data'] || []
        this.selectedAgent = data?.data?.['agent_id']
      } catch (e) {
        throw new Error(e)
      }
      this.isLoading = false
    },
    async saveCatalogData() {
      this.errors = []

      const METHOD = this.selectedItem?.['id'] ? 'put' : 'post'
      const URI = `admin/catalogs-data/${this.selectedItem?.['id'] || ''}`

      try {
        const { data } = await this.$axios[METHOD](URI, {
          catalog_id: this.catalog_id,
          agent_id: this.selectedAgent,
          fields_data: this.fieldsData
        })
        if(data?.code === 201) {
          this.$emit('saved')
          this.$emit('close')
        }
      } catch ({ error }) {
        this.errors = error.response?.data?.errors || []
        throw new Error(error)
      }
    },
    async deleteCatalogData() {
      try {
        const { data } = await this.$axios.delete(`admin/catalogs-data/${this.selectedItem['id']}`)
        if(data?.code === 202) {
          this.$emit('saved')
          this.$emit('close')
        }
      } catch (e) {
        throw new Error(e)
      }
    },
    arrayToObject() {
      if(this.catalogFields && this.catalogFields.length) {
        this.fieldsData = this.catalogFields.reduce((acc, value) => ({ ...acc, [value]: '' }), {})
      }
    }
  }
}
</script>

